import React from "react";
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <img src={require('./assets/Logo.png')} alt="Logo" className="logo2" />
            <h1 className="title2">SquadsBot</h1>
            <h3 className="Support">Сервер поддержки</h3>
            <h3 className="SupportUs">Поддержать нас</h3>
            <h3 className="Terms">Условия пользования</h3>
            <h3 className="Policy">Конфиденциальность</h3>
            <p className="madeby">Веб-сайт сделан RanVix и Yarovich!</p>
            <p className="copyright">Copyright © 2024 RanVix. Все права защищены. Скачивание и копирование не допускается
            </p>
        </div>
    );
};

export default Footer;