import React from "react";
import './Header.css';

const Header = () => {
    return (
        <div className="header">
            <img src={require('./assets/Logo.png')} alt="Logo" className="logo" />
            <h1 className="title">SquadsBot</h1>
            <div className="buttons">
                <p className="button">команды</p>
                <p className="button">сквады</p>
                <p className="button">премиум</p>
                <p className="button">поддержка</p>
            </div>
            <img src={require('./assets/AlignLeft.png')} alt="Menu" className="Menu" />
        </div>
    );
};

export default Header;
