import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// Установка viewport, если это необходимо
const setViewport = () => {
    const viewport = document.querySelector("meta[name=viewport]");
    if (viewport) {
        viewport.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
    } else {
        const meta = document.createElement('meta');
        meta.name = "viewport";
        meta.content = "width=device-width, initial-scale=1.0";
        document.head.appendChild(meta);
    }
};

setViewport();

ReactDOM.render(<App />, document.getElementById("root"));