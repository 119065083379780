import React, { useEffect, useState } from 'react';
import './BodyStyles.css';

const BodyConst = () => {
    const [squadsCount, setSquadsCount] = useState(0);
    const [serversCount, setServersCount] = useState(0);
    const [membersCount, setMembersCount] = useState(0);

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await fetch('https://squadsbot.ru/api/memberscount');
                const data = await response.json();
                setMembersCount(data.count);
            } catch (error) {
                console.error('Ошибка при получении данных:', error);
            }
        }
        const fetchSquads = async () => {
            try {
                const response = await fetch('https://squadsbot.ru/api/squadscount');
                const data = await response.json();
                setSquadsCount(data.count);
            } catch (error) {
                console.error('Ошибка при получении данных:', error);
            }
        };

        const fetchServers = async () => {
            try {
                const response = await fetch('https://squadsbot.ru/api/serverscount');
                const data = await response.json();
                setServersCount(data.count);
            } catch (error) {
                console.error('Ошибка при получении данных:', error);
            }
        };

        fetchSquads();
        fetchServers();
        fetchMembers();
    }, []);
    return (
        <div className="background">
            <div className="content">
            <img src={require('./assets/BigLogo.png')} alt="Logo" className="center-logo" />
                <h1 className="main-title">Squads</h1>
                <h2 className="sub-title">Лучший бот с клановой системой!</h2>
                <button className="action-button" onClick={() => window.location.href = 'https://discord.com/oauth2/authorize?client_id=1302604827389329449'}>
                    <img src={require('./assets/dis.png')} alt="dis" className="button-image" />
                    ДОБАВИТЬ В DISCORD
                </button>
                <div className="SQUADS">СКВАДЫ</div>
                <div className="TEGS">ТЕГИ</div>
                <div className="ROLES">РОЛИ</div>
                <div className="MANAGE">УПРАВЛЕНИЕ</div>
            </div>
            <img src={require('./assets/shadow.png')} alt="Back" className="backgroundshadow" />
            <img src={require('./assets/blackback.png')} alt="Back" className="backgroundblack" />
            <img src={require('./assets/Background.png')} alt="Back" className="background2" />
            <img src={require('./assets/star.png')} alt="star" className="star" />
            <img src={require('./assets/star.png')} alt="star" className="star2" />
            <img src={require('./assets/star.png')} alt="star" className="star3" />
            <img src={require('./assets/star.png')} alt="star" className="star4" />
            <img src={require('./assets/star.png')} alt="star" className="star5" />
            <img src={require('./assets/star.png')} alt="star" className="star6" />
            <img src={require('./assets/star.png')} alt="star" className="star7" />
            <img src={require('./assets/star.png')} alt="star" className="star8" />
            <img src={require('./assets/star.png')} alt="star" className="star9" />
            <img src={require('./assets/star.png')} alt="star" className="star10" />
            <img src={require('./assets/star.png')} alt="star" className="star11" />
            <img src={require('./assets/star.png')} alt="star" className="star12" />
            <img src={require('./assets/star.png')} alt="star" className="star13" />
            <img src={require('./assets/screen1.png')} alt="screen1" className="screen1" />
            <p className='SQUADStext'>Доступны на всех серверах, где есть бот! 
                Есть публичные и приватные. Вы можете назначить модераторов или администраторов сквада, 
                которые могут управлять сквадом в рамках своих прав. </p>
            <img src={require('./assets/screen2.png')} alt="screen" className="screen2" />
            <p className='TEGStext'>Тег вашего сквада будет отображаться на всех серверах! В конце или в начале ника, 
                со скобочками или без, креативьте, чтобы выделяться! Бот автоматически меняет ники всех участников сквада 
                на всех серверах.</p>
            <p className='ROLEStext'>На всех серверах участникам вашего сквада будет выдаваться роли с его тегом, 
                названием, и цветом, который вы хотите! Роль выдаётся автоматически, 
                и отображается отдельно в списке среди остальных.</p>
            <img src={require('./assets/screen3.png')} alt="screen" className="screen3" />
            <p className='MANAGEtext'>Удобная настройка тега, названия, описания, публичности, 
                цвета embed-сообщения, роли, места отображения тега, способа отображения тега сквада 
                в одной команде! Полный функционал команды доступен с премиумом.</p>
            <img src={require('./assets/newscreenversion.png')} alt="screen" className="screen4" />
            <div className="background2">
                <h1 className='StatsText'>СТАТИСТИКА</h1>
                <img src={require('./assets/SquadsNumb.png')} alt="squads" className="squadsnumber" />
                <img src={require('./assets/Users.png')} alt="users" className="usersnumber" />
                <img src={require('./assets/Servers.png')} alt="servers" className="serversnumber" />
                <p className='squadstextn'>Сквадов</p>
                <p className='userstextn'>Пользователей</p>
                <p className='serverstextn'>Серверов</p>
                <p className='squadstextnum'>{squadsCount}</p>
                <p className='userstextnum'>{membersCount}</p>
                <p className='serverstextnum'>{serversCount}</p> 
            </div>
        </div>
    );
};
export default BodyConst;